<div class='scp-layout'>
  @if (lobby) {
    @for (item of lobby; track item.type) {
      @switch (item.type) {
        @case ('BONUSES_ITEM') {
          <scp-lobby-item [isAuthorized]='isAuthorized'
                          [title]='item.title'
                          [link]='item.link'
                          [class]='item.type'
                          [onlyForAuthorized]='true'
                          [beginning]='component.isBeginning() | async'
                          [end]='component.isEnd() | async'
                          (prev)='component.prev()'
                          (next)='component.next()'>
            <scp-lobby-item-bonuses #component
                                    [offers]='item.bonuses'
                                    [breakpoint]='item.breakpoint'>
            </scp-lobby-item-bonuses>
          </scp-lobby-item>
        }
        @case ('BANNERS_ITEM') {
          <scp-lobby-item [isAuthorized]='isAuthorized'
                          [title]='item.title'
                          [link]='item.link'
                          [class]='item.type'
                          [navigation]='false'>
            <scp-banner [slides]='item.banners'
                        [isAuthorized]='isAuthorized'
                        [isFTD]='(storageService.getCustomer() | async)?.isFTD || false'>
            </scp-banner>
          </scp-lobby-item>
        }
        @case ('TOP_WINNERS_ITEM') {
          <scp-lobby-item [isAuthorized]='isAuthorized'
                          [title]='item.title'
                          [link]='item.link'
                          [class]='item.type'
                          [navigation]='false'>
            <scp-top-winners [class.authorized]='isAuthorized'></scp-top-winners>
          </scp-lobby-item>
        }
        @case ('GAMES_CATEGORIES_ITEM') {
          <scp-lobby-item [isAuthorized]='isAuthorized'
                          [title]='item.title'
                          [link]='item.link'
                          [class]='item.type'
                          [navigation]='false'>
            <ng-container *ngTemplateOutlet='topTemplate'></ng-container>
            <ng-container *ngTemplateOutlet='centerTemplate'></ng-container>
          </scp-lobby-item>
        }
        @case ('OUTLET_ITEM') {
          <scp-lobby-item [isAuthorized]='isAuthorized'
                          [title]='item.title'
                          [link]='item.link'
                          [class]='item.type'
                          [navigation]='false'>
            <router-outlet (activate)='onActivate($event)'></router-outlet>
          </scp-lobby-item>
        }
      }
    }
  }
  @if (!lobby?.length) {
    <div>
      <div class='scp-layout__content'>
        <router-outlet (activate)='onActivate($event)'></router-outlet>
      </div>
    </div>
  }
</div>
