import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { AuthenticationService, StorageService } from '@scpc/modules/common/services';
import { RouterOutlet } from '@angular/router';
import { BannerComponent } from '@scpc/modules/common/components/banners/components/banner/banner.component';
import { LobbyItem } from '@scpc/dto/lobby';
import { LobbyItemComponent } from '@scpc/modules/lobby/components/lobby-item/lobby-item.component';
import {
  LobbyItemBonusesComponent,
} from '@scpc/modules/lobby/components/lobby-item-bonuses/lobby-item-bonuses.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TopWinnersComponent } from '@scpc/modules/common/components/top-winners/top-winners.component';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'scp-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgTemplateOutlet,
    RouterOutlet,
    LobbyItemComponent,
    LobbyItemBonusesComponent,
    BannerComponent,
    TopWinnersComponent,
  ],
})
export class LayoutComponent implements OnInit {

  @Input()
  public topTemplate: TemplateRef<any>;

  @Input()
  public centerTemplate: TemplateRef<any>;

  @Input()
  public lobby: LobbyItem[];

  @Input()
  public product: string;

  @Input()
  public search: boolean;

  protected isAuthorized: boolean;
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(protected readonly storageService: StorageService,
              private readonly authenticationService: AuthenticationService,
              private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  public ngOnInit() {
    this.authenticationService.isAuthorized().pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => this.updateAuthorization(value));
    this.authenticationService.authorization.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => this.updateAuthorization(value));
  }

  public onActivate(component: { isAuthorized: boolean }) {
    component.isAuthorized = this.isAuthorized;
    this.changeDetectorRef.markForCheck();
  }

  private updateAuthorization(value: boolean): void {
    this.isAuthorized = value;
    this.changeDetectorRef.markForCheck();
  }

}
